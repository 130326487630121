import { Grid } from "@mui/material";
import React from "react";

const DriveIntro = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Work When You Want</h2>
        <p>with no restrictions on the largest footprint in the northwest</p>
      </Grid>
      <Grid item xs={8} sx={{textAlign:'center'}}>
        <img
          src="/assets/drive/man.png"
          style={{ borderRadius: "20px" }}
          height={'150px'}
        />
      </Grid>
    </Grid>
  );
};

export default DriveIntro;
