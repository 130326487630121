import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const FooterPage = () => {
  return (
    <Grid container className="footer-main-container" px={4}>
      <Grid item md={1} lg={1} xl={1}></Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <img
          src={"/assets/logo1.png"}
          alt="linkedin"
          style={{ width: "150px", borderRadius: "10px" }}
        />
        <Typography variant="subtitle2">
        Taxihub is a rideshare platform facilitating peer to peer ridesharing
          by means of connecting passengers who are in need of rides from
          drivers with available cars to get from point A to point B with the
          press of a button.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        <ul className="footer-bar-item">
          <li style={{ paddingBottom: "10px" }}>
            <b>Quick Links</b>
          </li>
          <li className="footer-link">
            <Link to="/">Home</Link>
          </li>
          <li className="footer-link">
            <Link to="/drive">Drive</Link>
          </li>
          <li className="footer-link">
            <Link to="/blogs">Blogs</Link>
          </li>
          <li className="footer-link">
            <Link to="/about">About Us</Link>
          </li>
          <li className="footer-link">
            <Link to="/help">Help</Link>
          </li>
          <li className="footer-link">
            <Link to="/safety">Safety</Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        <ul className="footer-bar-item">
          <li style={{ paddingBottom: "10px" }}>
            <b>Follow Us</b>
          </li>
          <li>
            <Box className="social-icon">
              <a
                href="https://www.facebook.com/profile.php?id=61551184610888&name=xhp_nt__fb__action__open_user"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-item"
              >
                <img
                  src={"/assets/icons/facebook.png"}
                  alt="facebook"
                  style={{ width: "30px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/taxihub_uk?igsh=MTU4OTExZ244cjJtdg=="
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-item"
              >
                <img
                  src={"/assets/icons/instagram.png"}
                  alt="instagram"
                  style={{ width: "30px" }}
                />
              </a>
              <a
                href="https://twitter.com/Taxihub_UK"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-item"
              >
                <img
                  src={"/assets/icons/twitter.jpg"}
                  alt="linkedin"
                  style={{ width: "30px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/taxihub/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-item"
              >
                <img
                  src={"/assets/icons/linkedin.png"}
                  alt="pinterest"
                  style={{ width: "30px" }}
                />
              </a>
            </Box>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <ul className="footer-bar-item">
          <li>
            <b>Download App</b>
          </li>
          <li>
            <Box className="app-icons">
              <a
                href="https://play.google.com/store/apps/details?id=com.taxihub.passengerapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  // style={{ height: "50px" }}
                  src={"/assets/icons/Play Store-01 (1).png"}
                  alt="app1"
                />
              </a>
              <a
                href="https://apps.apple.com/gb/app/taxihub/id6479711995"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={"/assets/icons/Apple Store-01 (1).png"}
                  alt="app2"
                  // style={{  padding: "3px" }}
                />
              </a>
            </Box>
          </li>
        </ul>
      </Grid>
      <Grid item md={1} lg={1} xl={1}></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: "1%",
          textAlign: "center",
        }}
        mt={2}
      >
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Typography variant="subtitle2">
              Copyright @ <strong>Taxihub</strong>. All Rights Reserved.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: { sm: "center", md: "left" } }}
            >
              <Link to="/policy" style={{ color: "white" }}>
                <FiberManualRecordIcon sx={{ width: "7px", height: "7px" }} />{" "}
                Privacy Policy
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/terms" style={{ color: "white" }}>
                <FiberManualRecordIcon sx={{ width: "7px", height: "7px" }} />{" "}
                Terms and Conditions
              </Link>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <PhoneIcon /> &nbsp;<strong>Inquiry No.:</strong>&nbsp; +92
                  300 0000000&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Complaints No.:</strong>&nbsp;+92 300 0000000
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <EmailIcon />
              &nbsp; info@taxihub.uk
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterPage;
