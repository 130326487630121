import {  Grid } from "@mui/material";
import React from "react";

const DriveHero = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/drive/drive-image.jpg"
          width={"100%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Opportunity is everywhere</h1>
        <p className="hero-paragraph">
          Be your own boss! Hours are completely flexible. Drive mornings,
          evenings, weekdays, or weekends.
        </p>
        {/* <Box className="hero-container" pt={5}>
          <Box className="app-icons">
            <a href="http://" target="_blank" rel="noopener noreferrer">
              <img
                src={"/assets/icons/google play 1.png"}
                alt="app1"
                // style={{ height: "50px" }}
              />
            </a>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              <img
                src={"/assets/icons/apple-store-button 1.png"}
                alt="app2"
                // style={{ height: "50px", padding: "3px" }}
              />
            </a>
          </Box>
        </Box> */}
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/drive/drive-image.jpg"
          width={"100%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid>
    </Grid>
  );
};

export default DriveHero;
