import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const BlogCard = () => {
  return (
    <Grid container spacing={3} p={2} className="container-style-card">
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          height: { xs: "350px", sm: "370px", md: "300px", lg: "300px" },
          paddingLeft: "0px !important",
          paddingTop: "0px !important",
        }}
      >
        <img
          src="/assets/account-img.png"
          alt="images"
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
        <Link
          to={"/blogs/graphic-design/1"}
          style={{
            textDecoration: "none",
            border: "1px solid gray",
            padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          More Details
        </Link>
      </Grid>
    </Grid>
  );
};

export default BlogCard;
