import { Grid } from "@mui/material";
import React from "react";

const HeroSafety = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/drive/Carpool-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Our commitment to safety in the UK</h1>
        <p className="hero-paragraph">
          You deserve to be able to move safely. To look forwards to the
          opportunities ahead. To be connected with people and places that
          matter most. Which is why we’re focused on your safety – from setting
          new standards to developing technology with the goal of reducing
          incidents.
        </p>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/drive/Carpool-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid>
    </Grid>
  );
};

export default HeroSafety;
