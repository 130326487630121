import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import BlogCard from "../Component/Blog/BlogCard";
import { Link } from "react-router-dom";

const BlogsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      pl={3}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} mt={3} pl={2}>
        <Grid container spacing={3} className="container-style">
          <Grid item xs={12} md={6}>
            <Grid container spacing={3} p={2}>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  height: {
                    xs: "350px",
                    sm: "370px",
                    md: "300px",
                    lg: "300px",
                  },
                  paddingLeft: "0px !important",
                  paddingTop: "0px !important",
                }}
              >
                <img
                  src="/assets/account-img.png"
                  alt="images"
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
                <Link
                  to={"/blogs/graphic-design/1"}
                  style={{
                    textDecoration: "none",
                    border: "1px solid gray",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  }}
                >
                  More Details
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} p={1} pl={2}>
            <BlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3} p={1} pl={2}>
            <BlogCard />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={5}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Latest Blogs
        </Typography>
      </Grid>
      <Grid item xs={12} md={11} mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
            <BlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
            <BlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
            <BlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
            <BlogCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogsPage;
