import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FooterPage from "./Component/FooterPage";
import Navbar from "./Component/Navbar";
import HomePage from "./Pages/HomePage";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutPage from "./Pages/AboutPage";
import DrivePage from "./Pages/DrivePage";
import SafetyPage from "./Pages/SafetyPage";
import DriverSafetyPage from "./Pages/DriverSafetyPage";
import UserSafetyPage from "./Pages/UserSafetyPage";
import TermsPage from "./Pages/TermsPage";
import PolicyPage from "./Pages/PolicyPage";
import AccountPage from "./Pages/AccountPage";
import CookiesPopup from "./CookiesPopup";
import { ToastContainer } from "react-toastify";
import WhatsApp from "./Component/WhatsApp";
import { useState } from "react";
import BlogsPage from "./Pages/BlogsPage";
import BlogDetailsPage from "./Pages/BlogDetailsPage";
import { Helmet } from "react-helmet-async";

function App() {
  const [cookieValue, setCookieValue] = useState(null);
  return (
    <div className="App">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/help" element={<ContactUsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/drive" element={<DrivePage />} />
        <Route path="/safety" element={<SafetyPage />} />
        <Route path="/safety/driver" element={<DriverSafetyPage />} />
        <Route path="/safety/user" element={<UserSafetyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs/:title/:id" element={<BlogDetailsPage />} />
      </Routes>
      <FooterPage />
      <CookiesPopup setCookieValue={setCookieValue} />
      <WhatsApp cookieValue={cookieValue} setCookieValue={setCookieValue} />
      <ToastContainer />
    </div>
  );
}

export default App;
