import { Box, Grid } from "@mui/material";
import React from "react";

const UserDesigningSaferSafety = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <img
          src="/assets/drive/driving-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Designing a safer trip</h1>
        <p className="hero-paragraph">
          Safety features are designed into the app, so you can tell your loved
          ones where you are, and so there’s help if something unexpected
          happens.*
          <br />
          Stay connected and protected when you ride.
        </p>
      </Grid>
    </Grid>
  );
};

export default UserDesigningSaferSafety;
