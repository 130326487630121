import React from "react";
import { Grid } from "@mui/material";

const ForgetAbout = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Focused on safety, wherever you go</h1>
      </Grid>
      {/* <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
        <p className="froget-about-paragraph">
          A platform for managing rides and meals, and local deliveries, for
          companies of any size.
        </p>
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <img src="/assets/forget-icon-1.jpg" style={{ height: "200px" }} />
            <h3>Easy</h3>
            <p>
              Choose your ride and set your location. You'll see your driver's
              picture and vehicle details, and can track their arrival on the
              map.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <img src="/assets/forget-icon-2.jpg" style={{ height: "200px" }} />
            <h3>Safe</h3>
            <p>
              No phone calls to make, no pick-ups to schedule. With 24/7
              availability, request a ride any time of day, any day of the year
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <img src="/assets/forget-icon-3.jpg" style={{ height: "200px",objectFit:'100%',width:'100%' }} />
            <h3>Reliable</h3>
            <p>
              Rate your driver and provide anonymous feedback about your trip.
              Your input helps us make every ride a 5-star experience.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgetAbout;
